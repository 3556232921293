// using module.exports here so gatsby-config can read it
module.exports = {
    // TODO: Replace next line with your own public API key
    builderAPIKey: '8c799c5673ca4f77bbf74a4e3301df92',
      // airtableTables: ["Blog", "Verses", "Dhikrs"]
  airtableTables: ["Blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: [
    "slug",
    "title",
    "description",
    "altdesc",
  ],
  };